$yellow: #ff0;
$red: #f00;
$black: #000;
$ronco-color: #bd142f;

$max-width: 1200px;

$hand-width: 42%;
$mouth-width: 18%;

$ronco-width: 120px;
$responsive-ronco-width: 100px;

$mobile-breakpoint: 768px;