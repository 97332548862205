body {
    padding: 0 10px;
    background-color: $yellow;
    background-image: url(../images/polka-pattern.png); 
}

* { user-select: none; }

.container {
    max-width: $max-width;
    height: 100vh;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.github-fork-ribbon {
    &::before {
        background-color: #111;
    }

    @media (max-width: $mobile-breakpoint) {
        display: none;
    }
}