.hint {
    position: absolute;
    left: 3%;
    top: 65%;
    font-family: Bangers, sans-serif;
    font-weight: bold;
    font-size: 6vw;
    color: #103289;
    line-height: 8vw;
    letter-spacing: 2px;
    
    opacity: 0;
    transition: opacity 1s ease;
    &.visible { opacity: 1; }
    
    span {
        display: block;
        font-size: 9vw;
        animation: jump-up 1.7s 5s ease infinite;
    }
    
    @media (max-width: $mobile-breakpoint) {
        top: 60%;
        font-size: 8vw;
        line-height: 12vw;
        span { font-size: 14vw; }
    }
    @media (min-width: $max-width) {
        font-size: 50px;
        line-height: 70px;
        top: 70%;
        span { font-size: 90px; }
    }
}

@keyframes jump-up {
    0% { transform: translate3d(0,0,0); }
    10% { transform: translate3d(0,-10px,0); }
    20% { transform: translate3d(0,0,0); }
    100% { transform: translate3d(0,0,0); }
}
